'use client';

import { Anchor, Box, Button, Card, Center, PasswordInput, Stack, Text, TextInput, Title } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { signIn } from 'next-auth/react';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { redirect } from 'next/navigation';

const Login = () => {
    const { status } = useSession();
    status === 'authenticated' && redirect('/');
    const form = useForm({
        initialValues: {
            email: '',
            password: '',
        },
        validate: {
            email: isNotEmpty('Inserisci il tuo indirizzo mail'),
            password: isNotEmpty('Inserisci la tua password'),
        },
    });

    return (
        <Center>
            <Card miw={400} shadow="lg" p="xl">
                <Box
                    p="lg"
                    component="form"
                    onSubmit={form.onSubmit(async (values) => {
                        await signIn('credentials', {
                            email: values.email,
                            password: values.password,
                            redirect: true,
                            callbackUrl: '/',
                        });
                    })}
                >
                    <Stack>
                        <Title ta="center" order={2}>
                            Enami DataLogger
                        </Title>
                        <Text ta="center">Accedi</Text>
                        <TextInput
                            label="Email"
                            placeholder="Il tuo indirizzo email"
                            {...form.getInputProps('email')}
                        />
                        <PasswordInput
                            label="Password"
                            placeholder="La tua password"
                            {...form.getInputProps('password')}
                        />
                        <Anchor href="/reset-password" component={Link} fz="sm" ta="center">
                            Reimposta password
                        </Anchor>
                        <Button type="submit">Accedi</Button>
                    </Stack>
                </Box>
            </Card>
        </Center>
    );
};

export default Login;
